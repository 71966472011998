import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Newsletter from "../components/Newsletter";
import BackgroundImage from "gatsby-background-image";

import social_image from "../images/social_newsletter.png";

import NewsletterImage from "../components/newsletter_background";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

function AboutPage() {
  const data = NewsletterImage();
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO
        keywords={[`newsletter`, "Diego Valle", "Diego Valle-Jones"]}
        title={t("Newsletter and free shapefiles")}
        description={t("Subscribe to my newsletter to keep up-to-date on Diego Valle's projects and receive over 5 gigabytes of free shapefiles")}
        image={social_image}
      />
      <BackgroundImage
        fluid={data.map.childImageSharp.fluid}
        loading="eager"
        style={{
          height: `100vh`,
          width: `100vw`,
          backgroundColor: `transparent`,
          backgroundSize: `cover`,
          backgroundPosition: `center center`,
          display: `flex`,
          alignItems: `center`,
        }}
      >
        <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
          <Newsletter />
        </main>
      </BackgroundImage>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
