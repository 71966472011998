import { useStaticQuery, graphql } from "gatsby";

const NewsletterImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        map: file(relativePath: { eq: "humbolt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
            }
          }
        }
      }
    `
  );
  return data;
};

export default NewsletterImage;
